<template>
	<div class="cart-detail">
		<div class="cart-detail-left">
			<div class="header-box">Shopping cart</div>
			<div class="center-goods-list-box">
				<div class="goods-list">
					<div v-for="(item, index) in carList" :key="index" class="goods-item flex">
						<div class="img-box">
							<img class="w-h-full" :src="item?.goods?.image" alt="" />
						</div>
						<div class="right-box">
							<div class="title">
								{{ item?.goods?.title }}
							</div>
							<div class="price">$ {{ item?.goods?.price }}</div>
							<div class="item-bottom-box flex">
								<!-- <div class="price">${{ item?.goods?.price }}</div> -->
								<div class="add-down-box flex">
									<div @click.stop="carDownOnClick(item)" class="btn-down"></div>
									<div class="btn-num btn-nums flex-c">
										<input class="common-input flex-c" type="text" v-model="item.nums" @input="carChangeOnClick($event, item)" />
									</div>
									<div @click.stop="carUpOnClick(item)" class="btn-up"></div>
								</div>
								<div class="stock">Stock: {{ item?.goods?.stocks }}</div>
							</div>
						</div>
						<div @click="delCart(item, index)" class="close-btn point"></div>
					</div>
				</div>
			</div>
			<div class="bottom-box flex-b">
				<div class="title">Total:</div>
				<div class="price">$ {{ carTotalPrice }}</div>
			</div>
		</div>
		<div class="cart-detail-right">
			<div v-if="userInfo.email" class="right-top">
				<div class="right-top-title">Email address for receiving keys</div>
				<div class="right-top-content">{{ userInfo.email }}</div>
			</div>
			<div class="right-bottom">
				<div class="right-bottom-title">payment</div>
				<div class="right-bottom-content">
					<div class="row1 flex-b">
						<div class="text">Amount paid</div>
						<div class="price">$ {{ carTotalPrice }}</div>
					</div>
					<div class="row2">
						<div class="text">Method of payment</div>
					</div>
					<div class="row3">
						<div class="img"></div>
					</div>
					<div @click="checkOutOnClick" class="right-bottom-btn flex-c point">Check Out</div>
				</div>
			</div>
		</div>
		<van-overlay :show="payOverlay">
			<div class="float-box absolute-c">
				<div class="top">
					<iframe class="iframe" :src="iframeUrl" width="100%" height="100%"></iframe>
				</div>
				<div class="bottom">
					<div @click="cancelOnClick" class="btn flex-c">Cancel payment</div>
				</div>
				<div @click="payOverlay = false" class="pay-close-box point"></div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
	import { delCarApi, orderSubmitApi, orderPayApi, changeCarNumApi, orderCancelApi } from '@/network/api.js';
	import { throttle } from 'lodash';
	export default {
		computed: {
			...mapGetters(['isLogin']),
			...mapState(['userInfo', 'imgbaseUrl', 'carList']),
			carTotalPrice() {
				let totle = 0;
				this.carList.forEach((item) => {
					totle += item?.goods?.price * item?.nums;
				});
				console.log('购物车总价', totle);
				return totle.toFixed(1);
			}
		},
		data() {
			return {
				payOverlay: false,
				order_sn: '',
				iframeUrl: ''
			};
		},
		// watch: {
		// 	carList: {
		// 		handler(newVal, oldVal) {
		// 			console.log(1111, newVal, oldVal);
		// 			const mapNewVal = (newVal || []).map((item) => {
		// 				return {
		// 					id: item.id,
		// 					nums: item.nums
		// 				};
		// 			});
		// 			const mapOldVal = (oldVal || []).map((item) => {
		// 				return {
		// 					id: item.id,
		// 					nums: item.nums
		// 				};
		// 			});
		// 			console.log(1234, mapNewVal, mapOldVal, JSON.stringify(mapNewVal) !== JSON.stringify(mapOldVal));
		// 			if (JSON.stringify(mapNewVal) !== JSON.stringify(mapOldVal)) {
		// 				// console.log(123456, newVal, oldVal);
		// 				// const params = {
		// 				// 	data: JSON.stringify(
		// 				// 		newVal.map((item) => {
		// 				// 			return {
		// 				// 				goods_id: item.goods_id,
		// 				// 				num: item.nums
		// 				// 			};
		// 				// 		})
		// 				// 	)
		// 				// };
		// 				// addCartAllApi(params).then((res) => {
		// 				// 	this.getCartList();
		// 				// });
		// 			}
		// 		},
		// 		deep: true,
		// 		immediate: true
		// 	}
		// },
		methods: {
			...mapMutations(['setCarList']),
			...mapActions(['ReFreshUserInfo', 'getCartList', 'mergeCartList']),
			async checkOutOnClick() {
				if (!this.isLogin) {
					this.$parent.$children[0].showLoginOverlay = true;
					return;
				}
				await this.orderSubmitFun();
				await this.orderPayFun();
				this.payOverlay = true;
			},
			async orderSubmitFun() {
				const params = {
					ids: this.carList.map((item) => item.id),
					email: this.userInfo.email
				};
				const res = await orderSubmitApi(params);
				this.order_sn = res?.data?.order_sn;
				this.getCartList();
			},
			async orderPayFun() {
				const params = {
					order_sn: this.order_sn
				};
				const res = await orderPayApi(params);
				console.log('支付结果', res);
				this.iframeUrl = res.data.url;
			},
			async carDownOnClick(item) {
				if (item.nums > 1) {
					item.nums--;
					this.changeNums(item, item.nums);
				}
			},
			async carChangeOnClick(e, item) {
				item.nums = e.target.value;
				if (!item.nums || item.nums == '0') {
					item.nums = 1;
				}
				this.changeNums(item, item.nums);
			},
			async carUpOnClick(item) {
				item.nums++;
				this.changeNums(item, item.nums);
			},
			changeNums: throttle(async function (item, num) {
				console.log('changeNums');
				if (this.isLogin) {
					const params = {
						id: item.id,
						nums: num,
						type: 'nums'
					};
					try {
						await changeCarNumApi(params);
						await this.getCartList();
					} catch (error) {}
				}
			}, 3000),
			async delCart(item, index) {
				if (this.isLogin) {
					const params = {
						ids: item.id
					};
					const res = await delCarApi(params);
					this.getCartList();
				} else {
					const list = [...this.carList];
					list.splice(index, 1);
					this.setCarList(list);
				}
			},
			async cancelOnClick() {
				console.log(123, '取消支付');
				const params = {
					order_sn: this.order_sn
				};
				this.payOverlay = false;
				await orderCancelApi(params);
			}
		}
	};
</script>

<style lang="scss">
	.cart-detail {
		padding: 0.6rem 3.4rem 1.8rem;
		box-sizing: border-box;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.cart-detail-left {
			width: 7rem;
			min-height: 1rem;
			border: 0.01rem solid #b3cddb;
			.header-box {
				width: 100%;
				height: 0.64rem;
				padding: 0.2rem 0.3rem;
				box-sizing: border-box;
				font-weight: 500;
				font-size: 0.24rem;
				color: #333333;
				border-bottom: 0.01rem solid #b3cddb;
			}
			.center-goods-list-box {
				height: 6.51rem;
				overflow-y: scroll;
				padding: 0.3rem;
				.goods-list {
					.goods-item {
						width: 100%;
						height: 1.44rem;
						// background: pink;
						margin-bottom: 0.3rem;
						position: relative;
						.img-box {
							width: 1.44rem;
							height: 1.44rem;
							padding: 0.1rem;
							box-sizing: border-box;
							margin-right: 0.2rem;
							flex-shrink: 0;
							border: 0.01rem solid #d9e6ed;
						}
						.right-box {
							flex-grow: 1;
							padding-top: 0.15rem 0;
							.title {
								margin-bottom: 0.2rem;
								color: #333333;
								font-weight: 500;
								font-size: 0.18rem;
								color: #333333;
							}
							.price {
								margin-bottom: 0.1rem;
								font-weight: 500;
								font-size: 0.24rem;
								color: #333333;
							}
							.item-bottom-box {
								.add-down-box {
									width: 1.2rem;
									height: 0.32rem;
									margin-right: 0.2rem;
								}
								.stock {
									color: #666666;
								}
							}
						}
						.close-btn {
							width: 0.24rem;
							height: 0.24rem;
							background: url('~@/assets/images/close-btn.png') no-repeat;
							background-size: 100% 100%;
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}
			}
			.bottom-box {
				width: 100%;
				height: 0.84rem;
				background: #d9e6ed;
				padding: 0.3rem;
				box-sizing: border-box;
				font-weight: 500;
				font-size: 0.24rem;
				color: #4182a4;
				.title {
				}
				.price {
					font-weight: 600;
				}
			}
			// background: pink;
		}
		.cart-detail-right {
			width: 5rem;
			// min-height: 1rem;
			// border: 0.01rem solid #b3cddb;
			// background: pink;
			.right-top {
				width: 100%;
				// min-height: 1.41rem;
				margin-bottom: 0.3rem;
				border: 0.01rem solid #b3cddb;
				.right-top-title {
					width: 100%;
					height: 0.64rem;
					border-bottom: 0.01rem solid #b3cddb;
					padding: 0.2rem 0.3rem;
					box-sizing: border-box;
					font-size: 0.24rem;
					color: #333333;
				}
				.right-top-content {
					width: 100%;
					height: 0.76rem;
					padding: 0.3rem;
					box-sizing: border-box;
					color: #333333;
				}
			}
			.right-bottom {
				width: 100%;
				// min-height: 3.39rem;
				border: 0.01rem solid #b3cddb;
				.right-bottom-title {
					width: 100%;
					height: 0.64rem;
					border-bottom: 0.01rem solid #b3cddb;
					padding: 0.2rem 0.3rem;
					box-sizing: border-box;
					font-size: 0.24rem;
					color: #333333;
				}
				.right-bottom-content {
					padding: 0.2rem 0.3rem 0.3rem 0.3rem;
					box-sizing: border-box;
					.row1 {
						margin-bottom: 0.2rem;
						.text {
							color: #333333;
						}
						.price {
							font-weight: 600;
							font-size: 0.24rem;
							color: #4182a4;
						}
					}
					.row2 {
						margin-bottom: 0.16rem;
						.text {
							color: #333333;
						}
					}
					.row3 {
						margin-bottom: 0.3rem;
						.img {
							width: 0.98rem;
							height: 0.35rem;
							background: url('~@/assets/images/usdt-icon.png') no-repeat;
							background-size: 100% 100%;
						}
					}
					.right-bottom-btn {
						width: 100%;
						height: 0.44rem;
						background: #4182a4;
						color: #fff;
					}
				}
			}
		}
		.float-box {
			width: 70vw;
			height: 70vh;
			background: #ffffff;
			border-radius: 0.3rem;
			.top {
				width: 100%;
				height: 90%;
				padding: 0.2rem;
				box-sizing: border-box;
				.iframe {
					body {
						margin: 0;
					}
				}
			}
			.bottom {
				padding: 0 0.3rem;
				.btn {
					width: 100%;
					height: 0.44rem;
					background: rgba(65, 130, 164, 0.2);
					font-weight: 500;
					font-size: 0.16rem;
					color: #4182a4;
				}
			}
			.pay-close-box {
				position: absolute;
				top: -0.2rem;
				right: -0.6rem;
				width: 0.48rem;
				height: 0.48rem;
				background: url('~@/assets/images/pay-close.png') no-repeat;
				background-size: 100% 100%;
			}
		}
	}
</style>
